<template>
  <v-container>
    <div class="text-center">
      <h1 class="popup-title">
        {{ $t("selfScanning.shoppingSuccessful.orderSuccessful") }}
      </h1>
      <p>{{ $t("selfScanning.shoppingSuccessful.shoppingComplete") }}</p>
      <!-- <div class="justify-center d-flex">
        <h2>{{ orderId }}</h2>
      </div> -->

      <v-btn
        color="primary"
        block
        depressed
        min-width="150"
        @click="submit()"
        >{{ $t("selfScanning.shoppingSuccessful.exit") }}</v-btn
      >
    </div>
  </v-container>
</template>

<style lang="scss"></style>

<script>
export default {
  name: "SelfscanningCompleted",
  props: {},
  data() {
    return {
      orderId: null
    };
  },
  methods: {
    submit() {
      this.$router.push({
        name: "Home"
      });
    }
  },
  async created() {
    if (this.$route.query.orderId) {
      this.orderId = this.$route.query.orderId;
    }
  }
};
</script>
